<template>
  <div class="dashboard__container" v-if="user">
    <div class="dashboard__container--body flex flex-wrap">
      <div class="mb-3 whiteBack pa-3" style="width:50%; min-width: 200px;">
        <label for="">Add User to Pipeline:</label>
        <v-select :options="events" label="title" v-model="newPipeline" @input="addPipeline" />
      </div>
      <vue-good-table
        v-if="user && user.pipeline"
        :columns="columns"
        :rows="userPipeline"
        styleClass="vgt-table condensed"
        >
        <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'created'">
                <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
              </span>

              <span v-else-if="props.column.field == 'title'">
                <router-link :to="`/orders/` + props.row.id + `/staffing/pipeline`">
                  {{props.row.title}}
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'applicants'">
                <span v-if="props.row.applicants" style="color:green;">
                  {{props.row.applicants.length}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'delete'">
                <button class="icon" v-tooltip="'delete instance'" @click.prevent="deletePipe(props.row)">
                  <i class="fas fa-trash ml-2 mr-2"></i>
                </button>
              </span>
              <span v-else-if="props.column.field == 'admins'">
                <span v-if="props.row.admins && props.row.admins.length > 0">
                  <button class="btn btn__small chip mr-2" v-for="(item, index) in props.row.admins" :key="item.id">{{item.name}}</button>
                </span>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
      </vue-good-table>

     
      <Loader v-if="(!user.pipeline || user.pipeline.length == 0)" />
    </div>
  </div>
</template>

<style scoped>
  .galleryImage {
    width: calc(33.33% - 2rem);
    height: auto;
  }
</style>


<script>
import { mapState } from 'vuex'
import router from '@/router'
import Loader from '@/components/Loader.vue'

export default {
  props: ['user'],
  data: () => ({ 
    newPipeline: '',
    columns: [
      {
        label: 'Title',
        field: 'title',
      },
      // {
      //   label: 'Delete',
      //   field: 'delete',
      // },
      // {
      //   label: 'Members',
      //   field: 'members',
      // },
    ],
  }),
  name: 'userPipeline',
  computed: {
    ...mapState(['events', 'userProfile', 'currentUser', 'userPipeline']),
  },
  created () {
    if (!this.events || this.events.length < 1) {
      this.$store.dispatch("getEvents")
    }
    this.$store.dispatch("getUserPipeline", this.$route.params.id);
  },
  components: {
    Loader,
  },
  methods: {
    addPipeline(pipe) {
      console.log(pipe)
      this.$store.dispatch("addToPipeline", {
        eventId: pipe.id,
        userId: this.user.id
      })
      this.newPipeline = ''
      this.$store.dispatch("getUserPipeline", this.$route.params.id);
    },
    onRowClick(params) {
      let url = `/orders/` + params.row.id
      console.log(url)
      router.push(url)
    },
    deletePipe(item) {
      this.$store.dispatch("removeFromPipeline", {
        eventId: item.id,
        userId: this.user.id
      })
      // this.$store.dispatch("getUserPipeline", this.$route.params.id)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY, hh:mm a')
      } else {
        return null
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch('clearEventsState')
    this.$store.dispatch('clearUserPipeline')
  }
}

</script>